.content-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 20px rgba(0,0,0 0.2);
    background-color: #fff;
    border-radius: 8px;
}

.content-header {
    display: flex;
    flex-wrap: nowrap;
}

.content-title {
    font-size: 28px;
    margin-bottom: 15px;
    color: #333;
    flex: 1;
    width: 100%;
}

.contentimg {
    max-width: 20%;
    height: auto;
    margin-right: 20px;
}

p {
    line-height: 1.6;
    color: #555;
}

strong {
    font-weight: bold;
    color: #ff6600
}

.content-contentArticle {
    flex: 1;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #555;
}

.writer-info {
    font-style: italic;
}

/* Add more styles as needed */